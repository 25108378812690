
// 本文件为标签api
import { API_URL } from '@/constants/';
import axios from './axios';

export default {
  getQuestion(data) {
    return axios.get(`${API_URL}/questions`, {
      params: data,
    }).then((res) => res.data);
  },
  save(data) {
    return axios.post(`${API_URL}/questions/basic`, data)
      .then((res) => res.data);
  },
  get() {
    return axios.get(`${API_URL}/questions/basic`)
      .then((res) => res.data);
  },
};
